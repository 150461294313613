<template>
  <td style="vertical-align: top; text-align: left">
    <table id="incidentTable">
      <thead>
        <tr style="border: 1px solid gray">
          <th class="GVHeader" scope="col">Units</th>
          <th class="GVHeaderLeft" align="left" scope="col">Address</th>
          <th class="GVHeader" scope="col">City</th>
          <th class="GVHeader" scope="col">Nature</th>
          <th class="GVHeader" scope="col">Zone</th>
          <th class="GVHeader" scope="col">Time</th>
          <th class="GVHeader" scope="col">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="incidentList.length === 0">
          <td colspan="6">
            <h3 class="noIncidentHeader">There are no active incidents.</h3>
          </td>
        </tr>
        <tr
          class="incRow"
          v-for="i in orderedIncidentList"
          :key="i.id"
          :class="{ blueBackground : i.causedBlink }"
        >
          <td class="incRow">
            <table style="width:100%">
              <tr
                v-for="r in i.assignedResources"
                :key="r.id"
                :class="{ redBackground: r.causedBlink}"
                style="text-align: center"
              >
                <td style="white-space: nowrap;">
                  <span :class="resourceClass(r.resourceId)">{{r.resourceAlias}}</span>
                </td>
                <td style="white-space: nowrap;">
                  <span :class="statusClass(r.resourceStatus)">{{r.resourceStatus}}</span>
                </td>
              </tr>
            </table>
          </td>
          <td class="incRow" style="text-align: left">
            <a class="addressObject" v-if="isRestricted" :href="i.addressUrl">{{i.addressRES}}</a>
            <a class="addressObject" v-else :href="i.addressUrl">{{i.address}}</a>
          </td>
          <td class="incRow lightblue">
            <a target="blank" :href="i.cityUrl">{{i.city}}</a>
          </td>
          <td class="incRow">
            <span v-if="isRestricted">{{i.natureRES}}</span>
            <span v-else>{{i.nature}}</span>
          </td>
          <td class="incRow lightblue" v-html="asZone(i.zone)"></td>
          <td class="incRow" :class="timeClass(i.incidentDate)" style="white-space: nowrap;">{{i.incidentDate | asLocalTime}}</td>
          <td class="incRow" :class="agencyTagClass(i.agencyTag)">
            <span>{{i.agencyTag}}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </td>
</template>

<script>
import moment from "moment";
import { sortBy } from "lodash";

export default {
  data() {
    return {};
  },
  props: ["incidentList", "isRestricted"],
  computed: {
    orderedIncidentList() {
      if (this && this.$props) {
        var filtered = this.$props.incidentList.filter(i => !(this.isRestricted && i.hideForRestrictedUser));
        return sortBy(filtered, "incidentDate").reverse();
      } else return [];
    }
  },
  methods: {
    statusClass(status) {
      if (status === undefined || status.trim() === "") return "noClass";
      if (status.toUpperCase().startsWith("AT PATIENT")) {
        return "tsATPATIENT";
      }
      if (status.toUpperCase().startsWith("ER ")) {
        return "tsERHOSPITAL";
      }
      if (status.toUpperCase().startsWith("TO ")) {
        return "tsTOHOSPITAL";
      }
      if (status.toUpperCase().startsWith("AT ")) {
        return "tsATHOSPITAL";
      }

      var newStatusClass = "ts" + status.toUpperCase().replace(/ /g, "");
      return newStatusClass;
    },
    resourceClass(resource) {
      return "res" + resource.toUpperCase().replace(/ /g, "");
    },
    timeClass(theTime) {
      var localTime = new Date(theTime);
      if (localTime.toString().includes('00:00:00')) {
        return "timeHidden";
      }
      else {
        return "";
      }
    },
    agencyTagClass(agencyTag) {
      var newStatusClass = "tag" + agencyTag.toUpperCase().replace(/ /g, "");
      return newStatusClass;
    },
    asZone(value) {
      var splitZones = value.split("|");

      if (splitZones.length === 1) return value;

      var output = "";
      for (var z = 0; z < splitZones.length; z++) {
        output += splitZones[z].trim();
        output += "<br />";
      }
      return output;
    }
  },
  filters: {
    asLocalTime(value) {
      var momentDate = moment(value);
      var formattedTime = momentDate.format("HH:mm");
      return formattedTime;
    }
  }
};
</script>
